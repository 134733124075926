<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "../components/index.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    Index,
  },
  mounted() {
    this.setLocales();
  },
  methods: {
    setLocales() {
      const localesJson = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const locale = localesJson.keys().map((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          return locale;
        }
      });
      let language = (navigator.language || navigator.browserLanguage).toLowerCase() || "en";
      if(language.includes('-')){
        language = language.split('-')[0]
      }
      switch (language.toLowerCase()) {
        case 'zh-hk':
          language = 'zh'
          break;
        case 'zh-tw':
          language = 'zh'
          break;
        case 'zh-cn':
          language = 'zh'
          break;
      }
      if (locale.includes(language)) {
        this.$i18n.locale = language;
      }
      this.loading = false;
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  font-family: system-ui;
}
</style>
