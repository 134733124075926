<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
* {
  /* font-family: Graphik, Helvetica, Arial, sans-serif; */
  font-family: Manrope;
}
@font-face {
  font-family: "Graphik-Medium";
  src: url("./assets/font/Graphik-Medium.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik-Regular";
  src: url("./assets/font/Graphik-Regular.woff");
  font-weight: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("./assets/font/Manrope-Light.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "Graphik-Semibold";
  src: url("./assets/font/Graphik-Semibold.woff");
  font-weight: normal;
}
</style>
